import { http_botcells_api } from "../plugins/axios";
import store from "../store";
import common from "./common";

import { WIDGET_DEFAULTS } from "../configs/common";
export default class messages {
  static async getWidgetParameters(botId, integrationId) {
    let widgetParameters = {};
    let widgetParametersQuery = common.deserializeQueryUrl();

    const urlParams = new URLSearchParams(window.location.search);

    const langQuery = urlParams.get("lang");

    let widgetParametersTag = document
      .getElementById("botcells-widget")
      .getAttribute("widget_parameters");

    if (
      widgetParametersQuery.chatBotAnswerColor &&
      widgetParametersQuery.bot_id
    ) {
      widgetParameters = widgetParametersQuery;
    } else if (widgetParametersTag) {
      widgetParameters = JSON.parse(widgetParametersTag);
    } else {
      widgetParameters = await this.getWidgetDataAPI(botId, integrationId);
    }

    await store.dispatch("setWidgetParameters", widgetParameters);

    let locale = document
      .getElementById("botcells-widget")
      .getAttribute("locale");
    if (langQuery) {
      store.dispatch("setLocale", langQuery);
    } else if (locale) {
      store.dispatch("setLocale", locale);
    } else {
      store.dispatch("setLocale", widgetParameters.lang);
    }

    return widgetParameters;
  }

  static async getWidgetDataAPI(botId, integrationId) {
    const urlParams = new URLSearchParams(window.location.search);
    const langQuery = urlParams.get("lang");

    let locale = "";
    if (langQuery) {
      locale = langQuery;
    } else {
      locale =
        document.getElementById("botcells-widget").getAttribute("locale") ||
        "en_US";
    }

    await store.dispatch("setLocale", locale);
    let data = {};
    try {
      let response;
      if (!integrationId) {
        response = await http_botcells_api.get(
          `bots/${botId}/widget-settings?lang=${locale}`
        );

        if (response.data.data) {
          data = response.data.data;
        }
      } else {
        response = await http_botcells_api.get(
          `v1/integrations/web/${integrationId}?bot_id=${botId}&lang=${locale}`
        );

        if (response.data.data) {
          data =
            response.data.data.configurations &&
            response.data.data.configurations.widget;
        }
      }

      if (data) {
        data = this.overrideSettings(data);
        if (data.position === "left") {
          window.top.postMessage("widgetLeft", "*");
        }

        const urlParams = new URLSearchParams(window.location.search);
        const iframeDashboard = urlParams.get("iframe-dashboard");

        if (iframeDashboard === "true") {
          data.demoBackground = "";
          data.position = "right";
        }
      } else {
        data = WIDGET_DEFAULTS;
      }
    } catch (err) {}
    return data;
  }

  static getExternalApiData() {
    // let externalAttributes = document.getElementById('botcells-widget').getAttribute('botcells-data')
    // if (!common.isEmptyObj(externalAttributes)) {
    //   externalAttributes = common.convertObjToArr(JSON.parse(externalAttributes))
    //   store.dispatch('setExternalAttributes', externalAttributes)
    // }
    // fetch("https://api.ipify.org?format=json")
    //   .then((x) => x.json())
    //   .then(({ ip }) => {
    //     this.myIPIsFetched(ip);
    //   })
    //   .catch((error) => {
    //     //console.error('Error:', error);
    //     this.myIPIsFetched("0.0.0.0");
    //   });
  }

  // static myIPIsFetched(ip) {
  //   let externalAttributes = document
  //     .getElementById("botcells-widget")
  //     .getAttribute("botcells-data");
  //   if (!common.isEmptyObj(externalAttributes)) {
  //     externalAttributes = common.convertObjToArr(
  //       JSON.parse(externalAttributes)
  //     );
  //   } else {
  //     externalAttributes = [];
  //   }
  //   externalAttributes.push({ attribute: "user_ip", value: ip });
  //   store.dispatch("setExternalAttributes", externalAttributes);
  // }

  static overrideSettings(data) {
    let overrideData = document
      .getElementById("botcells-widget")
      .getAttribute("widget-settings-override");
    if (overrideData && !common.isEmptyObj(overrideData)) {
      overrideData = JSON.parse(overrideData);
      for (let key in overrideData) {
        if (overrideData.hasOwnProperty(key)) {
          data[key] = overrideData[key];
        }
      }
    }
    return data;
  }

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
