<template>
  <div class="faqs">
    <div class="botcells-intro-form-card mt-3 mb-3">
      <v-form ref="faqSearch" @submit="searchFaqs" onSubmit="return false;">
        <h4>
          {{
            $store.state.core.widgetParameters.faqsTitle
              ? $store.state.core.widgetParameters.faqsTitle
              : $t("Find an answer your self")
          }}
        </h4>
        <v-text-field
          outlined
          color="primary"
          v-model="searchString"
          height="40"
          @input="searchFaqs"
          :rules="[(v) => !!v || $t('Please enter a search keyword')]"
          :placeholder="
            $store.state.core.widgetParameters.faqsPlaceholder
              ? $store.state.core.widgetParameters.faqsPlaceholder
              : $t('Search FAQs')
          "
          class="botcells-faq-search mt-2"
          append-outer-icon="mdi-chevron-right"
          @click:append-outer.prevent="searchFaqs"
        ></v-text-field>
      </v-form>
    </div>

    <div
      class="botcells-intro-form-card mb-3 botcells-faq-results pa-0 pt-4"
      v-if="resultsActive"
    >
      <h5>{{ $t(`Search results for`) }} "{{ searchString }}"</h5>
      <div v-if="faqsResults.length">
        <div
          v-for="(faq, index) in faqsResults"
          :key="index"
          class="botcells-faq-item"
          :id="`botcells-faq-item-${index}`"
        >
          <div class="botcells-faq-item-header">
            <h3>{{ $t("Help Center") }}</h3>
            <div class="botcells-faq-item-close">
              <v-icon color="white" @click="closeModal(index)"
                >mdi-chevron-left</v-icon
              >
            </div>
          </div>
          <div class="botcells-faq-item-desc" @click="openModal(index)">
            <h4 class="">{{ faq.body.question }}</h4>
            <p>{{ faq.body.answer }}</p>
          </div>
        </div>
      </div>
      <div v-else class="pr-5 pl-5 mb-3">
        {{ $t("No results found") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq",
  data() {
    return {
      searchString: "",
      faqsResults: "",
      resultsActive: false,
    };
  },
  methods: {
    searchFaqs() {
      if (this.$refs.faqSearch.validate() && this.searchString.length > 2) {
        this.faqsResults = [];
        let introWrapper = document.getElementsByClassName("intro-wrapper")[0];
        introWrapper.style.top = "-230px";
        setTimeout(() => {
          let scrollElement = document.querySelector(".botcells-intro-forms");
          if (scrollElement)
            scrollElement.scrollTop = scrollElement.scrollHeight + 50;
        }, 200);
        this.resultsActive = true;
        this.$http_botcells_backend
          .get(
            `widget/faqs/${this.$store.state.core.widgetParameters.lang}?bot_id=${this.$store.state.core.botId}&search=${this.searchString}`
          )
          .then((response) => {
            this.faqsResults = response.data;
          })
          .catch((error) => {});
      }
    },
    openModal(index) {
      let item = document.getElementById(`botcells-faq-item-${index}`);
      if (!item.classList.contains("botcells-faq-item-active")) {
        item.classList.add("botcells-faq-item-active");
      }
    },
    closeModal(index) {
      let item = document.getElementById(`botcells-faq-item-${index}`);
      item.classList.remove("botcells-faq-item-active");
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss">
.v-text-field--outlined .v-input__append-outer {
  margin-top: 0px;
  margin-bottom: 0px;
  background: var(--primaryColor);
  padding: 8px;
  margin-left: 0;
  color: white;
  border-radius: 0 5px 5px 0;
  .v-icon--link {
    color: white !important;
  }
}

.botcells-faq-search .v-input__slot {
  padding: 0 12px;
  border-radius: 5px 0 0 5px !important;
}

.botcells-faq-results h5 {
  padding: 0 20px;
}
.botcells-faq-results {
  overflow-y: scroll;
}

.botcells-faq-item {
  border-bottom: 1px solid #eee;
  transition: 0.5s ease;
  cursor: pointer;
  .botcells-faq-item-desc {
    padding: 9px 20px;
  }
  .botcells-faq-item-header {
    display: none;
  }
}

.botcells-faq-item h4 {
  font-size: 14px;
  font-weight: normal;
  color: var(--primaryColor);
}

.botcells-faq-item p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #aaa;
  font-size: 13px !important;
}

.botcells-faq-item-active {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background: white;
  .botcells-faq-item-desc {
    margin-top: 80px;
  }
  .botcells-faq-item-header {
    display: block;
    background: var(--primaryColor);
    padding: 20px;
    color: white !important;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    h3 {
      color: white !important;
    }

    .botcells-faq-item-close {
      position: absolute;
      left: 20px;
      top: 23px;
    }
  }
  h4 {
    font-size: 17px;
    font-weight: normal;
    line-height: 26px;
    color: black;
  }
  p {
    overflow: visible;
    white-space: initial;
    text-overflow: ellipsis;
    color: #333;
    font-size: 13px !important;
  }
}
</style>
