<template>
  <div>
    <v-date-picker
      :weekday-format="getDay"
      v-model="picker"
      full-width
      first-day-of-week="-1"
      show-adjacent-months
      :min="new Date().toISOString()"
      color="primary"
      ref="date-picker-ref"
      @input="updateDate"
      :locale="$store.state.core.widgetParameters.lang"
    ></v-date-picker>
  </div>
</template>

<script>
export default {
  name: "date-picker",

  components: {},
  props: ["availableDates"],

  data: () => ({
    picker: null,
  }),

  mounted() {
    this.picker = new Date().toISOString().slice(0, 10);
    this.updateDate();
  },

  methods: {
    getDay(date) {
      const daysOfWeek = [
        this.$t("weekDaysText.Sunday"),
        this.$t("weekDaysText.Monday"),
        this.$t("weekDaysText.Tuesday"),
        this.$t("weekDaysText.Wednesday"),
        this.$t("weekDaysText.Thursday"),
        this.$t("weekDaysText.Friday"),
        this.$t("weekDaysText.Saturday"),
      ];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getAllowedDates(val) {
      // val = val.replace(/-/g, '/');
      // val = dayjs(val).format('MM/DD/YYYY')
      return this.availableDates.indexOf(val) !== -1;
    },
    updateDate() {
      this.$emit("input", this.picker);
    },
  },
};
</script>
<style lang="scss">
.booking-steps {
  .v-date-picker-table table th {
    color: var(--primaryColor) !important;
  }
  .v-date-picker-table--date .v-btn {
    color: var(--secondary-color);
  }
  .v-date-picker-table--date .v-btn--active {
    color: white;
  }
  .v-date-picker-header .v-date-picker-header__value {
    flex: none !important;
    order: 1 !important;
  }
  @media screen and (max-width: 800px) {
    .v-date-picker-table table th {
      font-size: 7px !important;
    }
  }
}

@media (max-width: 960px) {
  .booking-steps .v-date-picker-table {
    height: 230px !important;
  }
}
</style>
