<template>
  <div class="botcells--widget">
    <div class="botcells-modal">
      <transition name="fade">
        <div class="botcells-modal-wrapper" key="1" v-if="sessionActive">
          <div class="botcells-modal-head">
            <div class="botcells-agents-list">
              <div class="align-center d-flex">
                <div
                  class="botcells-back-button"
                  @click="backToIntro"
                  v-if="
                    !widgetConfigurations.hideFaqs ||
                    !widgetConfigurations.hideForms ||
                    widgetConfigurations.displayIntroButtons
                  "
                >
                  <span class="mdi mdi-chevron-left"></span>
                </div>
                <div
                  class="botcells-agent-avatar-square ml-2"
                  v-if="widgetConfigurations.avatarType === 'square'"
                >
                  <img :src="widgetConfigurations.logo" alt="" />
                </div>
                <div class="botcells-agent-avatar d-flex ml-2" v-else>
                  <img :src="widgetConfigurations.botAvatar" alt="" />
                </div>
                <div class="ml-2 mr-2">
                  <h5>{{ widgetConfigurations.botName }}</h5>
                  <div class="botcells-agent-status d-flex align-center">
                    <div
                      class="botcells-online-indecator"
                      :class="{ 'botcells-online': connected }"
                    >
                      <span></span>
                    </div>
                    <p v-if="connected">{{ $t("online") }}</p>
                    <p v-else>{{ $t("back in minutes") }}</p>
                  </div>
                </div>
                <div
                  class="botcells-close-mobile"
                  :class="{
                    'botcells-close-desktop':
                      widgetConfigurations.hideOpenButton,
                  }"
                  @click="openModal"
                >
                  <img
                    class="botcells-modal-close-btn"
                    :src="widgetConfigurations.closeBubbleImage"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <widget-body
            :session-active="sessionActive"
            :modal-active="modalActive"
            :on-focus="modalOnFocus"
          />
        </div>

        <div
          class="botcells-intro"
          key="2"
          v-else-if="
            !widgetConfigurations.hideFaqs ||
            !widgetConfigurations.hideForms ||
            widgetConfigurations.displayIntroButtons
          "
        >
          <div class="botcells-intro-header">
            <img :src="widgetConfigurations.logo" alt="" />
            <h3 class="botcells-intro-title">
              {{ widgetConfigurations.header }}
            </h3>
            <p>{{ widgetConfigurations.subHeader }}</p>
          </div>
          <div class="botcells-close-mobile" @click="openModal">
            <img
              class="botcells-modal-close-btn"
              :src="widgetConfigurations.closeBubbleImage"
              alt=""
            />
          </div>
          <div class="botcells-intro-forms" v-if="$store.state.core.botId">
            <div class="intro-wrapper">
              <continue-conversation
                v-if="
                  common.getCookie('botcells_guid') &&
                  common.getCookie(`conversation_active_${guid}`) == '1'
                "
                @continueConversation="continueSession"
              />

              <intro-buttons @start-chat="startSession" />
              <intro-form @start-chat="startSession"></intro-form>
              <faqs v-if="!widgetConfigurations.hideFaqs"></faqs>
              <social-links />
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div
      v-if="
        !botcellsStarted &&
        !widgetConfigurations.hideOpenButton &&
        !channelsButtonsActive
      "
    >
      <offline-message v-if="widgetConfigurations.offLineMessageAppearance" />
    </div>

    <open-button
      @openModal="openModal"
      @activeChannels="channelsButtonsActive = $event"
    />
  </div>
</template>

<script>
import BotSocket from "../modules/botSocket";
import common from "../utils/common";
import widgetParameters from "../utils/widgetParameters";
import continueConversation from "./continueConversation";
import faqs from "./faqs/faqs";
import introButtons from "./intro-buttons/introButtons";
import introForm from "./intro-forms/intro-forms";
import offlineMessage from "./offlineMessage";
import openButton from "./openButton";
import socialLinks from "./socialLinks";
import widgetBody from "./widgetBody";

export default {
  name: "botcells-widget",
  data() {
    return {
      sessionActive: false,
      modalActive: false,
      modalOnFocus: false,
      botcellsStarted: false,
      channelsButtonsActive: false,
      botSocketInstance: null,
      common,
    };
  },
  components: {
    widgetBody,
    introForm,
    faqs,
    offlineMessage,
    socialLinks,
    continueConversation,
    openButton,
    introButtons,
  },
  async mounted() {
    this.initBotSocket();
    await this.getWidgetParameters();
    this.$i18n.locale = this.$store.state.core.locale;

    window.addEventListener("click", (e) => {
      if (document.querySelector(".botcells-modal").contains(e.target)) {
        this.modalOnFocus = true;
      } else {
        this.modalOnFocus = false;
      }
    });

    this.changeState();
    this.openWidgetExternal();
    this.handleRelativePosition();
  },
  methods: {
    initBotSocket() {
      if (!this.$store.state.core.socket) {
        new BotSocket();
      }
    },
    openMultipleChannels() {},
    openModal() {
      window.top.postMessage("toggleWidget", "*");
      setTimeout(() => {
        document
          .getElementsByClassName("botcells-modal-toggle-btn")[0]
          .classList.toggle("botcells-modal-btn-active");
        document
          .getElementsByClassName("botcells-modal")[0]
          .classList.toggle("botcells-modal-active");
        this.modalActive = document
          .getElementsByClassName("botcells-modal")[0]
          .classList.contains("botcells-modal-active");
      }, 100);

      if (!this.botcellsStarted) {
        let introDisabled =
          this.widgetConfigurations.hideFaqs &&
          this.widgetConfigurations.hideForms &&
          !this.widgetConfigurations.displayIntroButtons;
        let formInitiated = common.getCookie("formInitiated");
        if (
          (introDisabled || formInitiated) &&
          (common.getCookie(`conversation_active_${this.guid}`) == "1" ||
            introDisabled)
        ) {
          this.sessionActive = true;
        } else {
          this.sessionActive = false;
        }
        this.getFormData();
      }

      this.botcellsStarted = true;
      // wait for the animation, then update focus
      setTimeout(() => {
        this.modalOnFocus = this.modalActive;
      }, 500);
    },
    startSession() {
      common.setCookie("formInitiated", true, 1 / 24);
      this.$store.dispatch("setStartNew", true);
      this.$store.dispatch("setSessionActive", true);
      this.sessionActive = true;
    },
    continueSession() {
      this.$store.dispatch("setStartNew", false);
      this.$store.dispatch("setSessionActive", true);
      this.sessionActive = true;
    },
    endSession() {
      common.delete_cookie("formInitiated");
      common.delete_cookie("botcells_guid");
      this.$store.dispatch("setStartNew", true);
      this.botcellsStarted = false;
      this.sessionActive = false;
      this.modalActive = false;
      this.$store.dispatch("setConversationList", []);
      document
        .getElementsByClassName("botcells-modal-toggle-btn")[0]
        .classList.remove("botcells-modal-btn-active");
      document
        .getElementsByClassName("botcells-modal")[0]
        .classList.remove("botcells-modal-active");
    },
    backToIntro() {
      this.$store.dispatch("setInputDisabled", false);
      this.$store.dispatch("setConversationList", []);
      this.sessionActive = false;
      this.$store.dispatch("setStartNew", false);
      localStorage.setItem("lastMessageDate", new Date());
    },
    getFormData() {
      this.$store.dispatch("setIntroDataLoader", true);
      let lang = this.$store.state.core.locale;

      this.$http_botcells_backend
        .get(`widget/data/${lang}?bot_id=${this.$store.state.core.botId}`)
        .then((response) => {
          this.$store.dispatch("setMenus", response.data.menu);
          this.$store.dispatch("setStartForm", response.data.startForm);
          this.$store.dispatch("setEndForm", response.data.endForm);
          if (response.data["intro-buttons"]) {
            this.$store.dispatch(
              "setIntroButtons",
              response.data["intro-buttons"].buttonsList
            );
          }
          this.$store.dispatch("setIntroDataLoader", false);
        })
        .catch((error) => {
          this.$store.dispatch("setIntroDataLoader", false);
        });
    },
    openWidgetExternal() {
      let currentIndex = -1;
      let elements = document.getElementsByClassName(
        this.widgetConfigurations.externalOpenClass
      );
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener(
          "click",
          () => {
            let botId = elements[i].getAttribute("bot_id");
            let externalAttributes = elements[i].getAttribute("botcells-data");
            if (currentIndex !== i) {
              this.endSession();
            }
            if (botId) {
              this.$store.dispatch("setBotId", botId);
            }
            if (!common.isEmptyObj(externalAttributes)) {
              externalAttributes = common.convertObjToArr(
                JSON.parse(externalAttributes)
              );
              this.$store.dispatch("setExternalAttributes", externalAttributes);
            }
            setTimeout(() => {
              this.openModal();
            }, 200);

            currentIndex = i;
          },
          false
        );
      }
    },
    handleRelativePosition() {
      let widget = document.getElementById("app");
      const urlParams = new URLSearchParams(window.location.search);
      const position = urlParams.get("position");
      if (position === "relative") {
        widget.classList.add("widget-relative");
        setTimeout(() => {
          this.openModal();
        }, 2000);
      }
    },
    changeState() {
      if (window.addEventListener) {
        window.addEventListener("message", (e) => {
          if (e.data.type === "change-step") {
            if (e.data.value === "widget-offline") {
              document
                .getElementsByClassName("botcells-modal-toggle-btn")[0]
                .classList.remove("botcells-modal-btn-active");
              document
                .getElementsByClassName("botcells-modal")[0]
                .classList.remove("botcells-modal-active");
              this.botcellsStarted = false;
              this.sessionActive = false;
              this.modalActive = false;
            } else if (e.data.value === "widget-intro") {
              document
                .getElementsByClassName("botcells-modal-toggle-btn")[0]
                .classList.add("botcells-modal-btn-active");
              document
                .getElementsByClassName("botcells-modal")[0]
                .classList.add("botcells-modal-active");
              this.botcellsStarted = true;
              this.sessionActive = false;
              this.modalActive = true;
              this.getFormData();
            } else if (e.data.value === "widget-chat") {
              document
                .getElementsByClassName("botcells-modal-toggle-btn")[0]
                .classList.add("botcells-modal-btn-active");
              document
                .getElementsByClassName("botcells-modal")[0]
                .classList.add("botcells-modal-active");
              this.botcellsStarted = true;
              this.sessionActive = true;
              this.modalActive = true;
            }
          }
        });
      }
    },
    async getWidgetParameters() {
      const urlParams = new URLSearchParams(window.location.search);
      const botId = this.$store.state.core.botId
        ? this.$store.state.core.botId
        : urlParams.get("bot_id")
        ? urlParams.get("bot_id")
        : document.getElementById("botcells-widget").getAttribute("bot_id");

      const integrationId = this.$store.state.core.integrationId
        ? this.$store.state.core.integrationId
        : urlParams.get("integration_id")
        ? urlParams.get("integration_id")
        : document
            .getElementById("botcells-widget")
            .getAttribute("integration_id");

      if (!this.$store.state.core.botId) {
        this.$store.dispatch("setBotId", botId);
      }

      if (integrationId) {
        await this.$store.dispatch("setIntegrationId", integrationId);
      }

      await widgetParameters.getWidgetParameters(botId, integrationId);
    },
  },
  computed: {
    connected() {
      return this.$store.state.messages.connected;
    },
    guid() {
      return this.$store.state.core.guid;
    },
    chatOpen() {
      return this.widgetConfigurations.chatOpen;
    },
    widgetConfigurations: {
      get() {
        return this.$store.state.core.widgetParameters;
      },
      set(value) {
        this.$store.dispatch("setWidgetParameters", value);
      },
    },
  },
  watch: {
    sessionActive() {
      if (this.sessionActive) {
        BotSocket.handle();
      }
    },
    chatOpen(val) {
      if (val) {
        this.openModal();
      }
    },
  },
};
</script>

<style lang="scss" src="../style/components/widgets.scss"></style>
