import axios from "axios";
import Vue from "vue";

let backendUrl = document
  .getElementById("botcells-widget")
  .getAttribute("botcells-backend-url")
  ? document
      .getElementById("botcells-widget")
      .getAttribute("botcells-backend-url")
  : process.env.VUE_APP_BOTCELLS_BACKEND_URL;

let botcellsApi = document
  .getElementById("botcells-widget")
  .getAttribute("botcells-api-url")
  ? document.getElementById("botcells-widget").getAttribute("botcells-api-url")
  : process.env.VUE_APP_BOTCELLS_API_BASE_URL;

let apiBaseUpload = document
  .getElementById("botcells-widget")
  .getAttribute("upload-api-url")
  ? document.getElementById("botcells-widget").getAttribute("upload-api-url")
  : process.env.VUE_APP_UPLOADS_URL;

let botId = document.getElementById("botcells-widget").getAttribute("bot_id");

Vue.prototype.$http_botcells_backend = axios.create({
  baseURL: backendUrl,
});

const uploadsApiKey = process.env.VUE_APP_UPLOADS_API_KEY;

Vue.prototype.$http_upload = axios.create({
  baseURL: apiBaseUpload,
  headers: {
    "Content-Type": "multipart/form-data; boundary=MultipartBoundry",
    "x-api-key": uploadsApiKey,
  },
  params: {
    source: "conversation",
  },
});

Vue.prototype.$http_botcells_api = axios.create({
  baseURL: botcellsApi,
});

Vue.prototype.$http_without_base = axios.create({});

export const http_botcells_api = Vue.prototype.$http_botcells_api;
